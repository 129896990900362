import { useActiveProduct } from "@/core/context/ActiveProductContext"
import DashboardBlockDragDropProvider from "@/dashboard/DashboardBlockDragDropProvider"
import DashboardBlockList from "@/dashboard/DashboardBlockList"
import PathwayPageHeader from "@/pathway/PathwayPageHeader"
import ProductVisibilityBanner from "@/product/util/ProductVisibilityBanner"
import DiscoPage from "@disco-ui/page/DiscoPage"

export default function PathwayDashboard() {
  const product = useActiveProduct()!
  return (
    <DiscoPage headerContent={<PathwayPageHeader />}>
      <ProductVisibilityBanner marginBottom={3} />
      {product.dashboard ? (
        <DashboardBlockDragDropProvider dashboardId={product.dashboard.id}>
          <DashboardBlockList dashboardId={product.dashboard.id} position={"main"} />
        </DashboardBlockDragDropProvider>
      ) : null}
    </DiscoPage>
  )
}

import PathwayAdminDropdown from "@/admin/pathways/PathwayAdminDropdown"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import CreateInvitationButton from "@/invitation/create/button/CreateInvitationButton"
import HeaderContent from "@/main/page/header/HeaderContent"
import { PathwayPageHeaderFragment$key } from "@/pathway/__generated__/PathwayPageHeaderFragment.graphql"
import DiscoToolbarButton from "@disco-ui/toolbar/DiscoToolbarButton"
import { Theme, useMediaQuery } from "@material-ui/core"
import { useFragment } from "react-relay"
import { generatePath } from "react-router-dom"
import { graphql } from "relay-runtime"

export default function PathwayPageHeader() {
  const activeProduct = useActiveProduct()!
  const classes = useStyles()

  const product = useFragment<PathwayPageHeaderFragment$key>(
    graphql`
      fragment PathwayPageHeaderFragment on Product {
        ...PathwayAdminDropdownFragment
      }
    `,
    activeProduct
  )
  const permissions = activeProduct.viewerPermissions

  const showReports = permissions.has("members.manage")
  const showInvite = permissions.has("invitations.manage")

  const isXsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"))

  return (
    <HeaderContent
      rightOverflow={
        <div className={classes.buttons}>
          {showInvite && !isXsDown && (
            <CreateInvitationButton productId={activeProduct.id}>
              {(buttonProps) => (
                <DiscoToolbarButton
                  {...buttonProps}
                  icon={"user-add"}
                  tooltip={"Send an invite"}
                  testid={"HeaderContent.invite"}
                />
              )}
            </CreateInvitationButton>
          )}
          {showReports && !isXsDown && (
            <DiscoToolbarButton
              to={generatePath(ROUTE_NAMES.ADMIN.INSIGHTS.PATHWAYS.PATHWAY, {
                productSlug: activeProduct.slug,
              })}
              tooltip={"Reports"}
              icon={"chart-bar"}
            />
          )}
          <PathwayAdminDropdown
            testid={"PathwayPageHeader.more-actions"}
            productKey={product}
            classes={{ button: classes.dropdownButton }}
          />
        </div>
      }
    />
  )
}

const useStyles = makeUseStyles((theme) => ({
  buttons: {
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(0, -1),
  },
  dropdownButton: {
    width: "48px",
    height: "48px",
    padding: 0,
  },
}))

import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"

import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDrawer } from "@disco-ui"
import lazyWithRetry from "@utils/lazy/lazyWithRetry"

const PathwayGroupProductRegistrationDrawerContent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "pathway-group-product-registration" */ "@/pathway/pathway-group-product-registration/PathwayGroupProductRegistrationDrawerContent"
    )
)

function PathwayGroupProductRegistrationDrawer() {
  const drawer = useGlobalDrawer("pathwayGroupProductRegistration")
  const { drawerPGID, pId } = drawer.params
  const isOpen = Boolean(drawerPGID && pId)

  const classes = useStyles()

  return (
    <DiscoDrawer
      open={drawer.isOpen}
      onClose={handleClose}
      containerClasses={{ drawerContainer: classes.drawer }}
    >
      {isOpen && <PathwayGroupProductRegistrationDrawerContent />}
    </DiscoDrawer>
  )

  function handleClose() {
    drawer.close()
  }
}

const useStyles = makeUseStyles({
  drawer: {
    padding: 0,
  },
})

export default PathwayGroupProductRegistrationDrawer

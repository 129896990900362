import PostCard, { PostCardSkeleton } from "@/content/post/card/PostCard"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import { ScheduledPostsDrawerContentQuery } from "@/post/list/__generated__/ScheduledPostsDrawerContentQuery.graphql"
import { ScheduledPostsDrawerContent_PaginationFragment$key } from "@/post/list/__generated__/ScheduledPostsDrawerContent_PaginationFragment.graphql"
import { ScheduledPostsDrawerContent_PaginationQuery } from "@/post/list/__generated__/ScheduledPostsDrawerContent_PaginationQuery.graphql"
import OrganizationFeedPostsEmptyState from "@/post/list/empty-state/OrganizationFeedPostsEmptyState"
import ProductFeedPostsEmptyState from "@/post/list/empty-state/ProductFeedPostsEmptyState"
import { LocationStateControlledSharePostModal } from "@/post/share/SharePostModal"
import Relay from "@/relay/relayUtils"
import DiscoScrolledIntoView from "@disco-ui/scrolled-into-view/DiscoScrolledIntoView"
import { Grid } from "@material-ui/core"
import usePermissions from "@utils/hook/usePermissions"
import { useLazyLoadQuery, usePaginationFragment } from "react-relay"
import { graphql } from "relay-runtime"

const POSTS_PER_PAGE = 3

function ScheduledPostsDrawerContent() {
  const activeProduct = useActiveProduct()
  const drawer = useGlobalDrawer("scheduledPosts")
  const { feedId } = drawer.params

  const { feed } = useLazyLoadQuery<ScheduledPostsDrawerContentQuery>(
    graphql`
      query ScheduledPostsDrawerContentQuery(
        $feedId: ID!
        $first: Int!
        $after: String
        $kind: String
      ) {
        feed: node(id: $feedId) {
          ... on Feed {
            id
            name
            product {
              slug
            }
            ...usePermissionsFragment
            ...ScheduledPostsDrawerContent_PaginationFragment
              @arguments(first: $first, after: $after, kind: $kind, feedId: $feedId)
          }
        }
      }
    `,
    {
      feedId,
      first: POSTS_PER_PAGE,
      kind: "scheduled",
    },
    { fetchPolicy: "network-only" }
  )

  const { data, loadNext, hasNext, isLoadingNext, refetch } = usePaginationFragment<
    ScheduledPostsDrawerContent_PaginationQuery,
    ScheduledPostsDrawerContent_PaginationFragment$key
  >(
    graphql`
      fragment ScheduledPostsDrawerContent_PaginationFragment on Feed
      @refetchable(queryName: "ScheduledPostsDrawerContent_PaginationQuery")
      @argumentDefinitions(
        first: { type: "Int!" }
        after: { type: "String" }
        kind: { type: "String" }
        feedId: { type: "ID!" }
      ) {
        posts(first: $first, after: $after, kind: $kind)
          @connection(key: "ScheduledPostsDrawerContent__posts") {
          __id
          totalCount
          edges {
            node {
              id
              ...PostCardFragment @arguments(feedId: $feedId)
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    feed
  )

  const permisisons = usePermissions(feed)

  if (!data?.posts || !permisisons.has("posts.schedule")) return null

  const posts = Relay.connectionToArray(data.posts)
  const connectionId = data?.posts?.__id

  return (
    <>
      {posts.length ? (
        <>
          <Grid container spacing={4}>
            {posts.map((post, index) => (
              <Grid key={post.id} item xs={12}>
                <PostCard
                  testid={`ScheduledPost-${index}`}
                  postKey={post}
                  connectionId={connectionId}
                  feedId={feedId}
                  onPin={refetchFeed}
                  onUnpin={refetchFeed}
                  clearNotifications
                />
              </Grid>
            ))}
          </Grid>
          {hasNext && (
            <DiscoScrolledIntoView
              isLoading={isLoadingNext}
              onScrolledIntoView={() => loadNext(POSTS_PER_PAGE)}
            />
          )}
        </>
      ) : (
        getEmptyState()
      )}
      <LocationStateControlledSharePostModal />
    </>
  )

  function getEmptyState() {
    if (!feed) return null
    if (activeProduct) {
      return <ProductFeedPostsEmptyState canCreatePosts={true} />
    }
    return <OrganizationFeedPostsEmptyState canCreatePosts={true} />
  }

  function refetchFeed() {
    refetch(
      {
        feedId,
        first: POSTS_PER_PAGE,
        kind: "scheduled",
      },
      { fetchPolicy: "network-only" }
    )
  }
}

function ScheduledPostsDrawerContentSkeleton() {
  return (
    <div>
      <PostCardSkeleton />
      <PostCardSkeleton />
    </div>
  )
}

export default Relay.withSkeleton({
  component: ScheduledPostsDrawerContent,
  skeleton: ScheduledPostsDrawerContentSkeleton,
})

import { DeletePostButtonFragment$key } from "@/post/delete/__generated__/DeletePostButtonFragment.graphql"
import {
  DeletePostButtonMutation,
  DeletePostButtonMutation$variables,
} from "@/post/delete/__generated__/DeletePostButtonMutation.graphql"
import { displayErrorToast, displayToast } from "@components/toast/ToastProvider"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonProps,
} from "@disco-ui/button/OverridableDiscoButton"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import usePermissions from "@utils/hook/usePermissions"
import { observer } from "mobx-react-lite"
import { useState } from "react"
import { useFragment, useMutation } from "react-relay"
import ConnectionHandler from "relay-connection-handler-plus"
import { graphql } from "relay-runtime"

interface Props extends OverridableDiscoButtonProps {
  postKey: DeletePostButtonFragment$key
  connectionId?: string
  onDelete?(): void
}

function DeletePostButton(props: Props) {
  const { postKey, connectionId, onDelete, children, ...rest } = props

  const post = useFragment<DeletePostButtonFragment$key>(
    graphql`
      fragment DeletePostButtonFragment on Post {
        id
        feedId
        ...usePermissionsFragment
      }
    `,
    postKey
  )

  const permissions = usePermissions(post)

  const [isOpen, setIsOpen] = useState(false)

  const [commit, isDeleting] = useMutation<DeletePostButtonMutation>(graphql`
    mutation DeletePostButtonMutation($id: ID!, $connections: [ID!]!) {
      deletePost(id: $id) {
        deletedPostId @deleteEdge(connections: $connections) @deleteRecord
      }
    }
  `)

  if (!permissions.has("post.delete")) return null

  return (
    <>
      <OverridableDiscoButton {...rest} onClick={() => setIsOpen(true)}>
        {children}
      </OverridableDiscoButton>
      {isOpen && (
        <DiscoWarningModal
          testid={"DeletePostButton"}
          isOpen
          confirmationButtonProps={{
            onClick: handleDelete,
            shouldDisplaySpinner: isDeleting,
            children: "Yes, delete it",
          }}
          onClose={() => setIsOpen(false)}
          modalContentLabel={"Delete Post Dialog"}
          title={`Delete this Post?`}
          description={
            "Are you sure you want to permanently delete this post? There is no way to undo this action."
          }
        />
      )}
    </>
  )

  function handleDelete() {
    const variables: DeletePostButtonMutation$variables = {
      id: post.id,
      connections: connectionId ? [connectionId] : [],
    }

    commit({
      variables,
      updater: (store) => {
        if (!connectionId) {
          const feed = store.get(post.feedId)
          if (feed) {
            feed.invalidateRecord()
            const connections = ConnectionHandler.getConnections(feed, "FeedPosts__posts")
            connections.forEach((connection) => {
              ConnectionHandler.deleteNode(connection, post.id)
            })
          }
        }
      },
      onCompleted() {
        if (onDelete) onDelete()
        displayToast({ message: "Post deleted" })
        setIsOpen(false)
      },
      onError(error) {
        displayErrorToast(error)
      },
    })
  }
}

export default observer(DeletePostButton)

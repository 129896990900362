import { useAuthUser } from "@/core/context/AuthUserContext"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import CreatePostButton, { CreatePostButtonProps } from "@/post/add/CreatePostButton"
import { CreatePostInputQuery } from "@/post/add/__generated__/CreatePostInputQuery.graphql"
import Relay from "@/relay/relayUtils"
import ProfileAvatar from "@/user/common/avatar/ProfileAvatar"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoInput,
  DiscoInputProps,
  DiscoInputSkeleton,
  DiscoLink,
  DiscoSection,
  DiscoText,
  DiscoTooltip,
} from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import pluralize from "pluralize"
import { ReactNode } from "react"
import { graphql, useSubscribeToInvalidationState } from "react-relay"

type Props = Pick<CreatePostButtonProps, "refetch" | "feedId"> &
  Pick<DiscoInputProps, "disabled"> & {
    className?: string
    buttons?: ReactNode
    tooltip?: ReactNode
    noMargin?: boolean
  }

function CreatePostInput(props: Props) {
  const { buttons, disabled, tooltip, feedId, noMargin, ...rest } = props
  const { authUser } = useAuthUser({ required: true })
  const classes = useStyles({ noMargin })
  const drawer = useGlobalDrawer("scheduledPosts")

  const [{ node }, refetch] = Relay.useRefetchableQuery<CreatePostInputQuery>(
    graphql`
      query CreatePostInputQuery($feedId: ID!) {
        node(id: $feedId) {
          ... on Feed {
            __typename
            __id
            posts(kind: "scheduled") {
              totalCount
            }
          }
        }
      }
    `,
    { feedId: feedId || "" },
    { fetchPolicy: "store-and-network", refetchInBackground: true }
  )

  // feedId and feed will only be defined for feed blocks inside app dashboards, not
  // inside product/community dashboards
  const feed = Relay.narrowNodeType(node, "Feed")

  const refetchAll = () => {
    if (rest.refetch) rest.refetch()
    if (feedId) refetch({ feedId })
  }

  useSubscribeToInvalidationState(feed?.__id ? [feed.__id] : [], () => {
    refetchAll()
  })

  const scheduledPostCount = feed?.posts?.totalCount || 0

  const content = (
    <DiscoSection className={classes.section} groovyDepths={"xs"} border>
      <ProfileAvatar userKey={authUser} size={40} />
      <DiscoInput
        placeholder={"What would you like to share today?"}
        data-testid={"CreatePostInput"}
        readOnly
        fullWidth
        disableFocus
        disabled={disabled}
        classes={{
          input: classes.input,
        }}
      />
      {buttons}
    </DiscoSection>
  )

  return (
    <div className={classes.container}>
      {tooltip ? (
        <DiscoTooltip content={tooltip}>
          <span>{content}</span>
        </DiscoTooltip>
      ) : (
        <CreatePostButton
          checkQueryParamForModalOpen
          feedId={feedId}
          refetch={refetchAll}
          className={rest.className}
        >
          {(btnProps) => (
            <DiscoContainerButton {...btnProps}>{content}</DiscoContainerButton>
          )}
        </CreatePostButton>
      )}
      {scheduledPostCount > 0 && (
        <DiscoText color={"text.secondary"} variant={"body-xs"} component={"div"}>
          {"You have "}
          <DiscoLink
            onClick={openScheduledPostsDrawer}
            textVariant={"body-xs"}
          >{`${scheduledPostCount} scheduled ${pluralize(
            "post",
            scheduledPostCount
          )}`}</DiscoLink>
        </DiscoText>
      )}
    </div>
  )

  function openScheduledPostsDrawer() {
    if (!feedId) return
    drawer.open({ feedId })
  }
}

type StyleProps = {
  noMargin?: boolean
}

const useStyles = makeUseStyles((theme) => ({
  container: ({ noMargin }: StyleProps) => ({
    marginBottom: noMargin ? 0 : theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
  }),
  section: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.5),
    "&:hover": {
      boxShadow: theme.palette.groovyDepths.boxShadow,
    },
  },
  input: {
    cursor: "pointer",
    pointerEvents: "none",
  },
}))

export function CreatePostInputSkeleton() {
  const classes = useStyles({})
  return (
    <div className={classes.container}>
      <DiscoInputSkeleton height={90} />
    </div>
  )
}

export default Relay.withSkeleton({
  component: CreatePostInput,
  skeleton: CreatePostInputSkeleton,
})
